import { ImfProxy, HitType, BlockDto, BlockType } from "../services";
import { ConvertUtil } from "../util/convert";
import { DownloadUtil } from "../util/download";

export class DownloadService {
    rnwImfProxy: ImfProxy;

    constructor() {
        this.rnwImfProxy = new ImfProxy();
    }

    download(hitType: HitType, itemToDownloadId: BlockType, fileType: string = 'application/pdf'): Promise<void> {
        return new Promise((resolve, reject) => {
            this.rnwImfProxy
                .getFileBytes(new BlockDto({
                    hitType: hitType,
                    type: itemToDownloadId
                }))
                .then(encodedData => {
                    let blob = ConvertUtil.convertToBlob(encodedData, fileType);
                    DownloadUtil.download(blob, itemToDownloadId, fileType.substring(fileType.length -3));
                    resolve();
                })
                .catch(() => reject());
        });
    }
}
