import React from 'react';
import '../assets/fonts/symbols_89C3/symbols.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';

const Footer: React.FC = () => {
 return (
  <footer>
   <div className="imf-footer-socials">
    <a className="linkedin" href="http://www.linkedin.com/company/natixis" target="_blank" rel="noopener noreferrer">
     <i className="imf-symbol-linkedin"></i>
    </a>
    <a className="twitter" href="https://twitter.com/natixisresearch" target="_blank" rel="noopener noreferrer">
     <i className="imf-symbol-twitter"></i>
    </a>
    <a className="youtube" href="https://www.youtube.com/user/Natixisvideos" target="_blank" rel="noopener noreferrer">
     <i className="imf-symbol-youtube"></i>
    </a>
    <a className="instagram" href="https://www.instagram.com/natixis_corp/?hl=fr" target="_blank" rel="noopener noreferrer">
     {/*<i className="imf-symbol-instagram"></i>*/}
     <FontAwesomeIcon icon={faInstagram} />
    </a>
   </div>
   <div className="imf-footer-copyright">&copy;Natixis 2021</div>
  </footer>
 );
}
export default Footer;
