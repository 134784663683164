import React from 'react';
import './app.scss';
import Layout from './layout';
import Footer from './layout/footer';
import Header from './layout/header';
import Content from './layout/content';

const App: React.FC = () => {
  return (
   <div className="app">
    <Layout>
     <Header />
     <Content />
     <Footer />
     </Layout>
   </div>
  );
}
export default App;
