import React from 'react';
import bpce_logo_sm from '../assets/images/bpce_logo/bpce_logo_164x24.png';
import bpce_logo_lg from '../assets/images/bpce_logo/bpce_logo_370x61.png';
import natixis_logo_sm from '../assets/images/natixis_logo/natixis_logo_104x22.png';
import natixis_logo_lg from '../assets/images/natixis_logo/natixis_logo_232x54.png';
import './header.scss';
import ResponsiveImageImf from '../components/responsive-image-imf';

const Header: React.FC = () => {
 return (
  <header>
    <a href="https://groupebpce.com" target="_blank" rel="noopener noreferrer">
      <ResponsiveImageImf small={bpce_logo_sm} medium={bpce_logo_lg} large={bpce_logo_lg} />
    </a>
    <a href="https://www.natixis.com" target="_blank" rel="noopener noreferrer">
      <ResponsiveImageImf small={natixis_logo_sm} medium={natixis_logo_lg} large={natixis_logo_lg} />
    </a>
  </header>
 );
}
export default Header;