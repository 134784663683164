import React, { Component, RefObject } from 'react';
import '../assets/fonts/symbols_89C3/symbols.scss';
import './block.scss';
import { HitType, BlockType } from '../services';
import { DownloadService } from '../services/dowloadService';
import ResponsiveImageImf from './responsive-image-imf';
import { SnackbarComponent, Message } from './snackbar';

interface Props {
    smallImage: string;
    mediumImage: string;
    largeImage: string;
    id: BlockType;
    fileType?: string;
    title: any;
    tag: string;
    tagColor: string;
}

interface State {
  navigator: any;
}

const mailTemplate = {
  title: 'A document from Natixis BPCE: ',
  body: 'Hello, %0D%0A%0D%0APlease find below the link to a document from Natixis BPCE: %0D%0A%0D%0A'
};

export default class Block extends Component<Props, State> {
  downloadService: DownloadService;
  snackbarRef: RefObject<SnackbarComponent>;

  constructor(props: Props) {
    super(props);
    this.state = { navigator: window.navigator };
    this.share = this.share.bind(this);
    this.download = this.download.bind(this);
    this.downloadService = new DownloadService();
    this.snackbarRef = React.createRef<SnackbarComponent>();
  }

  share() {
    //then get a unique share link
    let generatedLink: string = window.location.href + '?share=' + this.props.id;
    //then share
    if (this.state.navigator.share != undefined) {
      //use webshare api https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
      this.state.navigator.share({ title: mailTemplate.title, text: mailTemplate.title, url: generatedLink });
    }
    else {
      window.open(`mailto:?subject=${mailTemplate.title}${this.props.title}&body=${mailTemplate.body}${generatedLink}`, '_self');
    }
  }

  download() {
    var snackbar = this.snackbarRef.current!;
    snackbar.openSnackBar(Message.Loading);
    this.downloadService
      .download(HitType.Download, this.props.id, this.props.fileType)
      .then(() => {
        snackbar.closeSnackBar();
        snackbar.openSnackBar(Message.Succeed);
      })
      .catch(() => snackbar.openSnackBar(Message.Error));
  }

  render() {
    return (
      <div className="imf-block">
        <ResponsiveImageImf small={this.props.smallImage} medium={this.props.mediumImage} large={this.props.largeImage} />
        <p className="imf-block-title">{this.props.title}</p>
        <p className="imf-block-tag" style={{ backgroundColor: this.props.tagColor }}>{this.props.tag}</p>
        <div className="imf-block-options">
          <button className="imf-symbol-download" onClick={this.download}></button>
          <button className="imf-symbol-share" onClick={this.share}></button>
        </div>
        <SnackbarComponent message="" ref={this.snackbarRef} />
      </div>
    );
  }
}
