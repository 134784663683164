import React, { PureComponent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';


export enum Message {
  None = "None",
  Succeed = "Done",
  Error = "Something went wrong please try again later",
  Loading = "Loading ..."
}

interface State {
  isActive: boolean;
  message?: Message | null;
}

export class SnackbarComponent extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { isActive: false };
  }

  openSnackBar = (message: Message) => {
    this.setState({ message: message });
    let duration = this.state.message == Message.Loading ? 10000 : 3000;
    this.setState({ isActive: true }, () => {
      setTimeout(() => {
        this.setState({ isActive: false });
      }, duration);
    });
  }

  closeSnackBar = () => {
    this.setState({ isActive: false, message: null });
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.state.isActive}
        //autoHideDuration={6000}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{this.state.message}</span>} />
    );
  }

}