import React, { Component } from 'react';
import { ResponsiveImage, ResponsiveImageSize } from 'react-responsive-image';

interface Props {
 small: string;
 medium: string;
 large: string;
}


export default class ResponsiveImageImf extends Component<Props>  {

 render() {
  return (
   <ResponsiveImage>
    <ResponsiveImageSize
     default
     minWidth={0}
     path={`${this.props.small}`}
    />
    <ResponsiveImageSize
     minWidth={768}
     path={`${this.props.medium}`}
    />
    <ResponsiveImageSize
     minWidth={1024}
     path={`${this.props.large}`}
    />
   </ResponsiveImage>
  );
 }
}