import React, { Component, ReactNode } from 'react';
import { Container } from 'reactstrap';
import './layout.scss';

type Props = {
  children: ReactNode
}

export default class Layout extends Component<Props> {
  static displayName = Layout.name;

  render () {
   return (
    <Container>
     {this.props.children}
    </Container>
    );
  }
}
