import { BlockType } from '../services';

export class ConvertUtil {
  static convertToBlob(encodedData: string, contentType: string, sliceSize: number = 512): Blob {
      var byteCharacters = atob(encodedData);
      var byteArrays: Uint8Array[] = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
  }

  static toBlockType(value: string): BlockType {
    return Object.values(BlockType).includes(<BlockType>value) ? value as BlockType : BlockType.None;
    }
}
