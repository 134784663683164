import React from 'react';
import Home from '../pages/home';
import './content.scss';

const Content: React.FC = () => {
 return (
  <main>
   <Home />
  </main>
 );
}
export default Content;
