import React from 'react';
import brush_sm from '../assets/images/brush/Brush_IFM_sm_536x400.png';
import brush_lg from '../assets/images/brush/Brush_IFM_536x400.png';
import bpce_identity_m from '../assets/images/bpce_identity/bpce_identity_300x164.png';
import bpce_identity_lg from '../assets/images/bpce_identity/bpce_identity_@2x.png';
import natixis_glance_m from '../assets/images/natixis_glance/Visuel corpo_300x164.png';
import natixis_glance_lg from '../assets/images/natixis_glance/Visuel corpo_2x.png';
import presentation_m from '../assets/images/presentation/visuel2021_300x164.png';
import presentation_lg from '../assets/images/presentation/visuel2021_600x328.png';
import research_m from '../assets/images/research/research300x164.png';
import research_lg from '../assets/images/research/research600x328.png';

import ResponsiveImageImf from '../components/responsive-image-imf';
import Block from '../components/block';
import './home.scss';
import { BlockType, HitType } from '../services';
import { RouteComponentProps, withRouter } from 'react-router';
import { DownloadService } from '../services/dowloadService';
import { ConvertUtil } from '../util/convert';
import 'url-search-params-polyfill';
import { SnackbarComponent, Message } from '../components/snackbar';

class Home extends React.Component<RouteComponentProps<any>>
{
  downloadService: DownloadService = new DownloadService();
  snackbarRef: any;

  constructor(props) {
    super(props);
    this.snackbarRef = React.createRef<SnackbarComponent>();

  }

  componentDidMount() {
    let itemShared = this.getItemToShare();
    if (itemShared !== BlockType.None) {
      this.snackbarRef.current.openSnackBar(Message.Loading);
      this.downloadService.download(HitType.Share, itemShared).then(() => {
        this.snackbarRef.current.closeSnackBar();
        this.snackbarRef.current.openSnackBar(Message.Succeed);
      }).catch(() => this.snackbarRef.current.openSnackBar(Message.Error));
    }
  }

  render() {
    return (
      <div className="imf-home">
        <div className="imf-home-imgText">
          <ResponsiveImageImf small={brush_sm} medium={brush_lg} large={brush_lg} />
          <div className="imf-home-imgText-text">
            <div>
              Welcome<br />to the site dedicated to the
              <p>2021 IMF & World Bank<br />Annual Virtual Meetings</p>
            </div>
          </div>
        </div>
        <div className="imf-home-blocks">
          <Block id={BlockType.BPCEIdentity} smallImage={bpce_identity_lg} mediumImage={bpce_identity_m} largeImage={bpce_identity_m} title="BPCE 2021 Identity" tag="Institutional Brochures" tagColor="#AE1580" />
          <Block id={BlockType.NatixisShortPresentation} smallImage={natixis_glance_lg} mediumImage={natixis_glance_m} largeImage={natixis_glance_m} title="Natixis at a Glance 2021" tag="Institutional Brochures" tagColor="#AE1580" />
          <Block id={BlockType.BPCENatixisPresentation} smallImage={presentation_lg} mediumImage={presentation_m} largeImage={presentation_m} title="Groupe BPCE presentation" tag="Investor presentation" tagColor="#2B6897" />
          <Block id={BlockType.ResearchNatixis} fileType="application/zip" smallImage={research_lg} mediumImage={research_m} largeImage={research_m} title={["Inflation / Hydrogen", <br/>, "Financial Forecast / China"]} tag="Natixis Research publications" tagColor="#60A285" />
        </div>
        <div className="imf-home-contact">
          <span>Natixis IMF contact: imf-natixis@natixis.com</span>
          <a target="_blank" rel="noopener noreferrer" href="https://research.natixis.com/Site/">Access Natixis Research website</a>
        </div>
        <SnackbarComponent ref={this.snackbarRef} />
      </div>
    );
  }

  getItemToShare(): BlockType {
    let sharedItem = this.getUrlParams().get("share");
    if (sharedItem) {
      return ConvertUtil.toBlockType(sharedItem);
    }
    return BlockType.None;
  }

  getUrlParams(): URLSearchParams {
    if (!this.props.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }
}

export default withRouter(Home);