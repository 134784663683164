export class DownloadUtil {
  static download(blob: Blob, publicationName: string, fileType: string): void {
    publicationName = publicationName + '.' + fileType;
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, publicationName);
    }
    else {
      var a = window.document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = publicationName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}